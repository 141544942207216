import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Wrapper from "../components/wrapper"
import SEO from "../components/seo"
import styles from "../styles/about.module.scss"

const AboutPage = () => {
  return (
    <Layout className={styles.about}>
      <SEO title="Our Story" />
      <Wrapper sizeReadable>
        <h2>About Us</h2>
        <p>
          Brothers Public Kitchen fulfills a dream years in the making. The
          Bagneschi Brothers, Jason and Blair, grew up immersed in authentic,
          Italian home cooking and were blessed to start their culinary
          education in the kitchen of their Italian grandparents. They learned
          from the best and want to bring the spirit of their Italian ancestors
          to you. With their combined education, experience, love for food, and
          Bay area pride, they are honored to offer the community Old World
          family recipes and modern American fare using Northern California’s
          bounty of local and fresh ingredients.
        </p>
        <figure className="bio-photo" >
          <StaticImage
            alt="Photograph of Jason"
            formats={["auto","webp","avif"]}
            height={250}
            layout="fixed"
            placeholder="blurred"
            src="../images/team/Jason_Bagneschi_(Katy_Rayne_Photography).jpg"
            transformOptions={{cropFocus: "attention"}}
            width={200}
          />
        </figure>
        <h3 className="bio-title">
          Jason Bagneschi –{" "}
          <span className="font-sans font-reduce-20">Owner, Brother</span>
        </h3>
        <p>
          Jason attended culinary school at Diablo Valley College’s Hotel and
          Restaurant Management Program and has worked and managed in some of
          San Francisco’s most prestigious restaurants and bars including
          Quince, Wayfare Tavern, The Perennial, and Comstock Saloon.
        </p>
        <p>
          Jason is a graduate of De La Salle High School and received a Bachelor
          of Fine Arts degree in Theatre from the University of Utah’s Actor
          Training Program. Jason has been married since 2006, and a father of a
          daughter and a son.
        </p>
        <figure className="bio-photo" >
          <StaticImage
            alt="Photograph of Blair"
            formats={["auto","webp","avif"]}
            height={250}
            layout="fixed"
            placeholder="blurred"
            quality="70"
            src="../images/team/Blair_Bagneschi_(Katy_Rayne_Photography).jpg"
            transformOptions={{cropFocus: "attention"}}
            width={200}
          />
        </figure>
        <h3 className="bio-title">
          Blair Bagneschi –{" "}
          <span className="font-sans font-reduce-20">Owner, Brother</span>
        </h3>
        <p>
          Blair has opened five restaurants in his career and has bartended and
          managed sports bars, restaurants, and night clubs in Arizona. Locally
          Blair has worked at Walnut Creek Yacht Club, Va De Vi, Bar Cava, and
          Barrel Aged Cocktail Bar.
        </p>
        <p>
          Blair, also a De La Salle High School alum, is a graduate of Northern
          Arizona University’s W.A. Franke School of Business in Hotel and
          Restaurant Management.
        </p>
        <figure className="bio-photo" >
          <StaticImage
            alt="Photograph of Chef Andrew"
            formats={["auto","webp","avif"]}
            height={250}
            layout="fixed"
            placeholder="blurred"
            quality="70"
            src="../images/team/Chef_Andrew_Farr_(Katy_Rayne_Photography).jpg"
            width={200}
          />
        </figure>
        <h3 className="bio-title">
          Andrew Farr –{" "}
          <span className="font-sans font-reduce-20">
            Executive Chef, Brother From Another Mother
          </span>
        </h3>
        <p>
          Andrew was born in Japan into a military family. He spent his
          childhood moving around Europe, and the United States before settling
          in California. Andrew was very lucky to experience many cuisines,
          arts, and music of different regions that inspire his dishes today.
        </p>
        <p>
          Andrew’s obsession with cooking began while preparing simple childhood
          favorites for his roommates who saw his talents and encouraged him to
          pursue a career in the culinary arts. His hard work and dedication
          helped him quickly climb the ranks within the restaurant industry.
        </p>
        <p>
          This is Andrew’s debut as Executive Chef, and we think you will be
          pleased to say you knew him when.
        </p>
      </Wrapper>
    </Layout>
  )
}

export default AboutPage
